@import '../../../styles/customMediaQueries.css';
.heroSectionSlider {
  & :global(.slick-arrow) {
    position: absolute;
    z-index: 1;
    top: 15%;
    transform: none;
    bottom: auto;
    width: 50px;
    height: 50px;

    @media (min-width: 414px) {
      top: 20%;
    }
    @media (--viewportSmall) {
      top: 25%;
    }
    @media (--viewportMedium) {
      bottom: 30px;
      top: auto;
    }
    @media (--viewportMLarge) {
      bottom: 50px;
      width: 80px;
      height: 80px;
    }
  }
  & :global(.slick-prev) {
    left: 20px;
    @media (--viewportSmall) {
      left: 30px;
    }
    @media (--viewportMLarge) {
      left: 50px;
    }
  }
  & :global(.slick-next) {
    right: 20px;
    @media (--viewportSmall) {
      right: 30px;
    }
    @media (--viewportMLarge) {
      right: 50px;
    }
  }
  & :global(.slick-prev::before) {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODQiIGhlaWdodD0iODQiIHZpZXdCb3g9IjAgMCA4NCA4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF82NzdfMTgxNSkiPgo8cmVjdCB4PSIxNiIgeT0iOCIgd2lkdGg9IjUyIiBoZWlnaHQ9IjUyIiByeD0iMjYiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMi42NjY3IDM0SDUxLjMzMzMiIHN0cm9rZT0iIzEyMTIxMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMzIuNjY2NyAzNEw0MC42NjY3IDQyIiBzdHJva2U9IiMxMjEyMTIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTMyLjY2NjcgMzRMNDAuNjY2NyAyNiIgc3Ryb2tlPSIjMTIxMjEyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZF82NzdfMTgxNSIgeD0iMCIgeT0iMCIgd2lkdGg9Ijg0IiBoZWlnaHQ9Ijg0IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQgZHk9IjgiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iOCIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4wMzY0NDAxIDAiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3dfNjc3XzE4MTUiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3dfNjc3XzE4MTUiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    width: 50px;
    height: 50px;
    display: inline-block;
    @media (--viewportMLarge) {
      width: 80px;
      height: 80px;
    }
  }
  & :global(.slick-next::before) {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODQiIGhlaWdodD0iODQiIHZpZXdCb3g9IjAgMCA4NCA4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZF82NzdfMTgxMCkiPgo8cmVjdCB4PSIxNiIgeT0iOCIgd2lkdGg9IjUyIiBoZWlnaHQ9IjUyIiByeD0iMjYiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0zMi42NjY2IDM0SDUxLjMzMzMiIHN0cm9rZT0iIzEyMTIxMiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQzLjMzMzQgNDJMNTEuMzMzNCAzNCIgc3Ryb2tlPSIjMTIxMjEyIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNDMuMzMzNCAyNkw1MS4zMzM0IDM0IiBzdHJva2U9IiMxMjEyMTIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZF82NzdfMTgxMCIgeD0iMCIgeT0iMCIgd2lkdGg9Ijg0IiBoZWlnaHQ9Ijg0IiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPgo8ZmVPZmZzZXQgZHk9IjgiLz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iOCIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4wMzY0NDAxIDAiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3dfNjc3XzE4MTAiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3dfNjc3XzE4MTAiIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjwvZGVmcz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    width: 50px;
    height: 50px;
    display: inline-block;
    @media (--viewportMLarge) {
      width: 80px;
      height: 80px;
    }
  }
}
.heroContainer {
  position: relative;
  align-items: stretch;
  display: flex !important;
  width: 100%;
  background-color: var(--bgColor);
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
  }
  & .heroBg {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 100%;
    @media (--viewportMedium) {
      width: 50%;
    }
    @media (--viewportLargeWithPaddings) {
      width: 60%;
    }
    @media (--viewportXLarge) {
      height: 450px;
    }
    & > img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & .heroContent {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    padding: 24px;
    justify-content: center;
    width: 100%;
    @media (--viewportMedium) {
      width: 50%;
      padding: 30px 40px;
    }
    @media (--viewportLargeWithPaddings) {
      width: 40%;
      padding: 50px 60px;
    }
  }
}

.heading {
  composes: h1 from global;
}

.sectionSubHeading {
  composes: sectionSubHeading from global;
}
.para {
  composes: p from global;
}
.browseBtn {
  composes: buttonDefault from global;
  & > svg {
    margin-left: 5px;
  }
}
.productInfo {
  & .para {
    font-weight: var(--fontWeightSemiBold);
    margin: 0 0 8px 0;
  }
}
.tag {
  composes: p from global;
  background-color: var(--textColor);
  color: var(--colorWhite);
  text-transform: uppercase;
  padding: 4px 15px;
  border-radius: var(--borderRadiusMedium);
  margin-bottom: 5px;
  font-weight: var(--fontWeightSemiBold);
  display: inline-block;
}
