@import '../../../styles/customMediaQueries.css';

.sectionPadding {
  composes: commonPadding from global;
}
.fixedWidthContainer {
  composes: contentMaxWidthPages from global;
}
.popularSessions {
  width: 100%;
  display: block;
  overflow-x: hidden;
  @media (min-width: 1280px) {
    overflow: hidden;
    position: relative;
  }
  &.justInSec {
    padding-left: 24px;
    padding-right: 24px;
    & .fixedWidthContainer {
      padding: 0;
      & .sectionHead {
        padding: 0;
        & .leftSec {
          margin-bottom: 0;
        }
      }
      & .sectionListings {
        padding: 0;
        & :global(.slick-slider) {
          width: 100%;
          position: relative;
        }
        & :global(.slick-list) {
          position: relative;
          margin: 0 -10px;
          @media (min-width: 1300px) {
            overflow: hidden;
            margin: 0 -15px;
          }
        }
        & :global(.slick-track) {
          margin: 0;
        }
        & :global(.slick-slide) {
          padding: 0 10px;
          @media (min-width: 1300px) {
            padding: 0 15px;
          }
        }
        & :global(.slick-dots) {
          bottom: auto;
          top: -60px;
          right: 24px;
          text-align: right;
          @media (--viewportXLarge) {
            right: 0px;
          }
        }
      }
    }
  }
  & .contentWidth {
    display: block;
  }
  & :global(.slick-slider) {
    width: 100%;
    position: initial;
  }
  & :global(.slick-list) {
    position: initial;

    @media (min-width: 1300px) {
      overflow: visible;
    }
  }
  & :global(.slick-track) {
    margin: 0;
  }
  & :global(.slick-slide) {
    @media (min-width: 1300px) {
    }
  }
  & :global(.slick-dots) {
    bottom: auto;
    top: 0px;
    right: 24px;
    text-align: right;
    @media (--viewportXLarge) {
      right: 0px;
    }
    & > li {
      margin: 0;
      &:not(:last-child) {
        margin-right: 5px;
      }

      & > button {
        padding: 0;
        border: solid 1px transparent;
        border-radius: 100px;
        &::before {
          font-size: 8px;
          letter-spacing: -0.5px;
          line-height: 23px;
          opacity: 1;
        }
      }
    }
    & :global(.slick-active) {
      & > button {
        border: solid 1px var(--textColor);
      }
    }
  }
  & :global(.slick-arrow) {
    width: 30px;
    height: 30px;
    display: inline-flex;
    background-color: var(--colorWhite);
    align-items: center;
    justify-content: center;
    box-shadow: var(--boxShadowPopupLight);
    border-radius: 100px;
    z-index: 1;
    &:hover,
    &:focus {
      background-color: var(--marketplaceColor);
    }
  }
  & :global(.slick-arrow.slick-prev) {
    left: 16px;
  }
  & :global(.slick-arrow.slick-next) {
    right: 16px;
  }
  & :global(.slick-arrow.slick-prev.slick-disabled) {
    background-color: var(--colorGrey100);
  }
  & :global(.slick-arrow.slick-next.slick-disabled) {
    background-color: var(--colorGrey100);
  }
  & :global(.slick-arrow.slick-prev.slick-disabled:hover::before) {
    filter: invert(0);
  }
  & :global(.slick-arrow.slick-next.slick-disabled:hover::before) {
    filter: invert(0);
  }
  & :global(.slick-arrow.slick-prev.slick-disabled:focus::before) {
    filter: none;
  }
  & :global(.slick-arrow.slick-next.slick-disabled:focus::before) {
    filter: none;
  }

  & :global(.slick-arrow.slick-prev:hover::before) {
    filter: invert(1);
  }
  & :global(.slick-arrow.slick-next:hover::before) {
    filter: invert(1);
  }
  & :global(.slick-arrow.slick-prev:focus::before) {
    filter: invert(1);
  }
  & :global(.slick-arrow.slick-next:focus::before) {
    filter: invert(1);
  }
  & :global(.slick-arrow.slick-prev::before) {
    content: ' ';
    display: inline-block;
    width: 7px;
    height: 11px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDcgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjY1NDk2OCA1LjgwMTg2TDUuNzQyNzkgMTAuODc1NkM1LjkwOTQ5IDExLjA0MTcgNi4xNzkzOCAxMS4wNDE1IDYuMzQ1NzkgMTAuODc0N0M2LjUxMjA4IDEwLjcwODEgNi41MTE2NSAxMC40MzggNi4zNDQ5MyAxMC4yNzE3TDEuNTU5OTUgNS40OTk5OEw2LjM0NTExIDAuNzI4MjQyQzYuNTExOCAwLjU2MTk1NCA2LjUxMjIzIDAuMjkyMDg5IDYuMzQ1OTcgMC4xMjUzNzFDNi4yNjI1NCAwLjA0MTc5NzYgNi4xNTMyNSA5LjUwOTAyZS0wNiA2LjA0Mzk2IDkuNDk5NDZlLTA2QzUuOTM0OTUgOS40ODk5M2UtMDYgNS44MjYwOSAwLjA0MTUxODIgNS43NDI4MiAwLjEyNDUxMkwwLjY1NDk2OCA1LjE5ODEzQzAuNTc0NjgxIDUuMjc4MDEgMC41Mjk2MjggNS4zODY3MiAwLjUyOTYyOCA1LjQ5OTk4QzAuNTI5NjI4IDUuNjEzMjUgMC41NzQ4MSA1LjcyMTgzIDAuNjU0OTY4IDUuODAxODZaIiBmaWxsPSIjMDUxMDM2Ii8+Cjwvc3ZnPgo=);
  }
  & :global(.slick-arrow.slick-next::before) {
    content: ' ';
    display: inline-block;
    width: 7px;
    height: 11px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDcgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjM0NTA5IDUuMTk4MTRMMS4yNTcyNyAwLjEyNDM5MkMxLjA5MDU3IC0wLjA0MTc0NjMgMC44MjA2ODUgLTAuMDQxNDY3IDAuNjU0MjY3IDAuMTI1MjUxQzAuNDg3OTc5IDAuMjkxOTQ4IDAuNDg4NDA4IDAuNTYxOTgzIDAuNjU1MTI3IDAuNzI4MjVMNS40NDAxMSA1LjUwMDAyTDAuNjU0OTU1IDEwLjI3MThDMC40ODgyNTggMTAuNDM4IDAuNDg3ODI4IDEwLjcwNzkgMC42NTQwOTUgMTAuODc0NkMwLjczNzUxOSAxMC45NTgyIDAuODQ2ODEgMTEgMC45NTYxIDExQzEuMDY1MTEgMTEgMS4xNzM5NyAxMC45NTg1IDEuMjU3MjUgMTAuODc1NUw2LjM0NTA5IDUuODAxODdDNi40MjUzOCA1LjcyMTk5IDYuNDcwNDMgNS42MTMyOCA2LjQ3MDQzIDUuNTAwMDJDNi40NzA0MyA1LjM4Njc1IDYuNDI1MjUgNS4yNzgxNyA2LjM0NTA5IDUuMTk4MTRaIiBmaWxsPSIjMDUxMDM2Ii8+Cjwvc3ZnPgo=);
  }
  & .fixedWidthContainer {
    position: relative;
    & .sectionHead {
      padding-left: 24px;
      padding-right: 24px;
      @media (--viewportXLarge) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
  &.shopInstrumentSec {
    @media (max-width: 767px) {
      & :global(.slick-arrow) {
        background-color: transparent;
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        border-radius: 100px;
        position: absolute;
        z-index: 1;
        top: 16%;
        transform: none;
        bottom: auto;
        width: 48px;
        height: 48px;

        @media (--viewportMedium) {
          bottom: 30px;
          top: auto;
        }
        @media (--viewportMLarge) {
          bottom: 50px;
          width: 80px;
          height: 80px;
        }
      }
      & :global(.slick-arrow.slick-prev.slick-disabled) {
        background-color: transparent;
      }
      & :global(.slick-arrow.slick-next.slick-disabled) {
        background-color: transparent;
      }
      & :global(.slick-arrow.slick-prev:hover::before) {
        filter: none;
      }
      & :global(.slick-arrow.slick-next:hover::before) {
        filter: none;
      }
      & :global(.slick-arrow.slick-prev:focus::before) {
        filter: none;
      }
      & :global(.slick-arrow.slick-next:focus::before) {
        filter: none;
      }
      & :global(.slick-prev) {
        left: calc(50% - 60px);
        @media (--viewportSmall) {
          left: calc(50% - 60px);
        }
        @media (--viewportMLarge) {
          left: 50px;
        }
      }
      & :global(.slick-next) {
        right: calc(50% - 60px);
        @media (--viewportSmall) {
          right: calc(50% - 60px);
        }
        @media (--viewportMLarge) {
          right: 50px;
        }
      }
      & :global(.slick-prev::before) {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEgNS41SDExLjUiIHN0cm9rZT0iIzEyMTIxMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xIDUuNUw1LjUgMTAiIHN0cm9rZT0iIzEyMTIxMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xIDUuNUw1LjUgMSIgc3Ryb2tlPSIjMTIxMjEyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        width: 24px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      & :global(.slick-next::before) {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMiAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjUgNS41SDEiIHN0cm9rZT0iIzEyMTIxMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMS41IDUuNUw3IDEwIiBzdHJva2U9IiMxMjEyMTIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTEuNSA1LjVMNyAxIiBzdHJva2U9IiMxMjEyMTIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
        width: 24px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      & .sectionHead {
        text-align: center;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: 80px;
      }
    }
  }
}
.sliderItem {
  text-align: center;
  cursor: pointer;
  & .itemImg {
    width: 120px;
    height: 120px;
    border-radius: 100px;
    margin: 0 auto 12px;
    cursor: pointer;
    overflow: hidden;
    @media (--viewportMedium) {
      width: 140px;
      height: 140px;
    }
    @media (--viewportLarge) {
      width: 150px;
      height: 150px;
    }
    @media (--viewportMLarge) {
      width: 180px;
      height: 180px;
    }
    @media (--viewportXLarge) {
      width: 196px;
      height: 196px;
    }
    & > img {
      max-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & > p {
    margin: 0;
    font-size: 18px;
    font-weight: var(--fontWeightMedium);
    line-height: 130%;
    color: var(--textColor);
  }
}
.sectionHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-direction: row;
  @media (--viewportSmall) {
  }
  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
  & .leftSec {
    margin-bottom: 20px;
    @media (--viewportSmall) {
      margin-bottom: 0;
    }
  }
}
.aboutUsSec {
  margin-bottom: 40px;
  /* padding-left: 24px;
  padding-right: 24px; */
  & .fixedWidthContainer {
    background-color: var(--colorWhite);
    display: flex;
    align-items: flex-start;

    flex-direction: column;
    @media (--viewportLarge) {
      flex-direction: row;
    }
    & .contentSec {
      padding: 24px 0;
      width: 100%;
      @media (--viewportMedium) {
        padding: 40px 0px;
      }
      @media (--viewportLarge) {
        padding: 0px 52px;
        width: 50%;
      }
      & > h1 {
        color: var(--textColor);
        font-size: 28px;
        line-height: 110%;
        font-weight: 600;
        letter-spacing: 0em;
        margin: 0px 0 24px 0;
        padding: 0;
        @media (--viewportSmall) {
          font-size: 30px;
        }
        @media (--viewportMedium) {
          font-size: 35px;
        }
        @media (--viewportLarge) {
          font-size: 40px;
        }
      }
      & p {
        color: var(--textColor);
        font-weight: var(--fontWeightRegular);
        margin: 0 0 20px 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 170%;
        letter-spacing: 0em;
        @media (--viewportMedium) {
          font-size: 18px;
          margin: 0 0 28px 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      & .visitLink {
        color: var(--textColor);
        font-weight: 16px;
        font-weight: var(--fontWeightMedium);
        display: inline-block;
        align-self: flex-start;
        cursor: pointer;
        transition: var(--transitionStyle);
        @media (--viewportMedium) {
          font-size: 18px;
        }
        & > svg {
          & path {
            stroke: var(--textColor);
          }
        }
        &:hover {
          color: var(--marketplaceColor);
          text-decoration: none;
          transition: var(--transitionStyle);
          & > svg {
            & path {
              stroke: var(--marketplaceColor);
            }
          }
        }
      }
    }
    & .vdoSec {
      width: 100%;
      position: relative;
      @media (--viewportLarge) {
        width: 50%;
      }
      & .vdoImg {
        object-fit: contain;
        max-width: 100%;
        width: 100%;
        @media (--viewportLarge) {
          width: auto;
        }
      }
      & .playbtn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
}
.howWorksSec {
  /* padding-left: 24px;
  padding-right: 24px; */
  & .fixedWidthContainer {
    background-color: var(--bgColor);
    display: flex;
    flex-direction: column;
    padding: 0;
    @media (--viewportLarge) {
      flex-direction: row;
    }
    & .contentSec {
      padding: 24px;
      width: 100%;
      @media (--viewportMedium) {
        padding: 40px 52px;
      }
      @media (--viewportLarge) {
        padding: 68px 52px;
        width: 40%;
      }
    }
    & .vdoSec {
      position: relative;
      width: 100%;
      @media (--viewportLarge) {
        width: 60%;
      }
      & :global(.player-wrapper) {
        width: 100%;
        height: 100%;
        & > div {
          width: 100% !important;
          @media (--viewportLarge) {
            height: 100% !important;
          }
        }
      }
      & :global(.react-player__preview) {
        & > button {
          padding: 0;
          border: none;
          & > img {
            width: 48px;
            height: 48px;
            @media (--viewportMedium) {
              width: 96px;
              height: 96px;
            }
          }
        }
      }
      & > iframe {
        object-fit: cover;
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
      & .vdoImg {
        object-fit: cover;
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
      & .playbtn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
}
.sectionHeading {
  composes: sectionHeading from global;
}
.sectionPara {
  composes: sectionPara from global;
}
.heading {
  composes: howWorksHeading from global;
}
.paragraph {
  composes: paragraph from global;
}
.visitLink {
  composes: visitLink from global;
}
