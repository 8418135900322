@import '../../styles/customMediaQueries.css';

.sticky {
}

.sectionTitle {
  composes: h2 from global;
}
.sectionSubHeading {
  composes: sectionSubHeading from global;
}
.pageContent {
}
.loaderSection {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  opacity: 0.85;
  background: linear-gradient(270deg, rgba(5, 16, 54, 0.5) 0%, #051036 72%);
  & .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
